<template>
  <div class="content-wrapper">
    <section class="content-header p-1 px-2">
      <div class=" div-header container-fluid">
        <div style="border-bottom: 1px solid #0001" class="row mb-2">
          <div class="col-sm-12 d-flex justify-content-between align-items-center">
            <!-- <span></span> -->
            <h4 style="color: #0007" class="mt-3">
              <i class="fa fa-users mx-1"></i>
              Usuários
            </h4>
            <router-link tag="button"
                         :to="{ path: 'add_admin'}"
                         class="btn btn-primary mr-1 btn-sm"
                         title='Adicionar Usuário'>
              <i class="fa fa-user-plus mr-1"></i>Cadastrar
            </router-link>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </section>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Listagem de Usuários</h3>

              <div class="card-tools d-flex">
                <div class="input-group input-group-sm" style="width: 400px;position: relative !important">
                  <input @keyup="getFilterUsers()" v-model="searchUsers"
                         title="Pesquisar Usuário"
                         type="text"
                         name="search_admin"
                         class="form-control float-right input-search-admin"
                         placeholder="Usuário?"
                  />
                  <i class="fas fa-search search-admin" title="Pesquisar Usuário"
                     @click="getFilterUsers()"></i>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0" style="max-height: 440px;">
              <table class="table table-hover table-head-fixed">
                <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Tipo</th>
                  <th>Ações</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user, i) in users" :key="i">
                  <td>{{ user.name }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.role }}</td>
                  <td style="width:200px">
                    <router-link tag="button"
                                 :to="{ name: 'edit_admin', params: {id: user._id}}"
                                 class="btn btn-outline-primary mr-1 btn-sm"
                                 title='Editar User'>
                      <i class="fa fa-edit"></i>
                    </router-link>
                    <router-link tag="button"
                                 :to="{ name: 'delete_admin', params: {id: user._id}}"
                                 class="btn btn-outline-danger btn-sm"
                                 title='Deletar User'>
                      <i class="fa fa-trash"></i>
                    </router-link>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      users: [],
      searchUsers: '',
      usersGroup: [],
      mainUser: ''
    }
  },

  methods: {
    getUsers() {
      this.$tallos_admin_api.defaults.headers.common['Authorization'] = `Bearer ${this.$store.getters.tokenJWT}`

      this.$tallos_admin_api.get('auth/managers')
          .then(res => {
            this.users = res.data
            this.users = this.users.map(user => {
              switch(user.role){
                case 'admin':
                  user.role = 'Administrador'
                  break;
                case 'support':
                  user.role = 'Suporte'
                  break;
                case 'seller':
                  user.role = 'Vendedor'
                  break;
                case 'finance':
                  user.role = 'Financeiro'
                  break;
                case 'author':
                  user.role = 'Autor'
                  break;
              }
              return user
            })
            this.usersGroup = res.data
            this.mainUser = this.$store.getters.admin
            if (res.data == [] || res.data.length == 0) {
              this.$router.push('/')
            }
          })
          .catch(e => {
            this.$toasted.global.defaultError({msg: 'Erro ao buscar Users.'})
          })
    },

    getFilterUsers() {
      if (this.searchUsers === '') {
        this.getUsers()
      } else if (this.searchUsers !== '') {
        this.users = [...this.usersGroup]
        const filterUsers = this.users.filter((users, index) => {
          return users.name.match(new RegExp(this.searchUsers, "i"))
        })
        this.users = filterUsers
      }
    }
  },

  created() {
    this.getUsers()
    document.title = 'Tallos User - Usuários'
  }
}
</script>

<style scoped>

.content-wrapper {
  background-color: #fff;
}

.input-search-admin {
  border-radius: 3px !important;
}

.search-admin {
  position: absolute !important;
  top: 8px;
  right: 10px;
  cursor: pointer;
}

</style>